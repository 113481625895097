import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { CodeBlock } from '../../../components/CodeBlock';
import { SEO } from '../../../components/SEO';
import { Layout } from '../../../layouts/Layout';
import { styles as S } from '@ulule/owl-kit-components/dist/next.esm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Identity > Typography > Headings" mdxType="SEO" />
    <h1>{`Headings`}</h1>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XXXL" mdxType="CodeBlock">{`
  <S.heading.XXXL>Heading XXXL</S.heading.XXXL>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XXL" mdxType="CodeBlock">{`
  <S.heading.XXL>Heading XXL</S.heading.XXL>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XL" mdxType="CodeBlock">{`
  <S.heading.XL>Heading XL</S.heading.XL>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.L" mdxType="CodeBlock">{`
  <S.heading.L>Heading L</S.heading.L>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.M" mdxType="CodeBlock">{`
  <S.heading.M>Heading M</S.heading.M>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.serifM" mdxType="CodeBlock">{`
  <S.heading.serifM>Heading Serif M</S.heading.serifM>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.S" mdxType="CodeBlock">{`
  <S.heading.S>Heading S</S.heading.S>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XS" mdxType="CodeBlock">{`
  <S.heading.XS>Heading XS</S.heading.XS>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XXS" mdxType="CodeBlock">{`
  <S.heading.XXS>Heading XXS</S.heading.XXS>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XXXS" mdxType="CodeBlock">{`
  <S.heading.XXXS>Heading XXXS</S.heading.XXXS>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XXXXS" mdxType="CodeBlock">{`
  <S.heading.XXXXS>Heading XXXXS</S.heading.XXXXS>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.XXXXXS" mdxType="CodeBlock">{`
  <S.heading.XXXXXS>Heading XXXXXS</S.heading.XXXXXS>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.Card1" mdxType="CodeBlock">{`
  <S.heading.Card1>Heading Card 1</S.heading.Card1>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.Card2" mdxType="CodeBlock">{`
  <S.heading.Card2>Heading Card 2</S.heading.Card2>
`}</CodeBlock>
    <CodeBlock preview scope={{
      S
    }} title="S.heading.Card3" mdxType="CodeBlock">{`
  <S.heading.Card3>Heading Card 3</S.heading.Card3>
`}</CodeBlock>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      